/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface FortuneApplication {
  id?: number;
  name?: string;
  birthday?: string;
  gender?: string;
  bloodtype?: string;
  consultContent?: string;
  campaignId?: string;
  orderedWhileWaiting?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export type Achievement = {
  title?: string;
  value?: string;
  unit?: string;
}[];

export interface FortuneTellersDeilyInsight {
  fortuneTellerId?: number;
  chatRevenues?: number;
  telRevenues?: number;
  chatCustomers?: number;
  telCustomers?: number;
  chatArppu?: number;
  telArppu?: number;
  totalChatWaitingHours?: number;
  totalTelWaitingHours?: number;
  chatOrdersWhileWaiting?: number;
  telOrdersWhileWaiting?: number;
  chatOrdersRank?: number;
  telOrdersRank?: number;
  chatCustomersRank?: number;
  telCustomersRank?: number;
  chatArppuRank?: number;
  telArppuRank?: number;
  date?: string;
}

export interface FortuneTeller {
  id: number;
  selfIntroduction?: string | null;
  averageScore: number;
  reviewCounts: number;
  havePublishedChat: boolean;
  havePublishedTel: boolean;
  chatAcceptFlag: boolean;
  telAcceptFlag: boolean;
  waitingFlag: boolean;
  telWaitingFlag: boolean;
  isBusy: boolean;
  joinedSupportProgram: boolean;
  isOfficial: boolean;
  isDeleted: boolean;
  name: string;
  imageUrl: string;
  thumbnailUrl: string;
  bannerUrl?: string | null;
  processedBannerUrl?: string | null;
  bestPriceTelFortune?: {
    id: number;
    type: FortuneTellerTypeEnum;
    title: string;
    imageUrl: string;
    costPerMinute: number;
    averageScore: number;
    isOnlyFirstTime: boolean;
    isFavorited: boolean;
    isRepeater: boolean;
  } | null;
  featureTags?: {
    id: number;
    name: string;
    bannerUrl: string;
    variant: string;
    metaDescriptionType: string;
    menuCounts: number;
  }[];
}

export interface User {
  id?: number;
  name?: string | null;
  imageUrl?: string | null;
  birthday?: string | null;
  gender?: string | null;
  deleted?: boolean;
}

export interface SampleChatRoom {
  id?: number;
  fortuneTeller?: FortuneTeller;
  user?: User;
  order?: FortuneApplication;
}

export interface Tag {
  id?: number;
  name?: string;
  bannerUrl?: string;
  variant?: string;
  metaDescriptionType?: string;
}

export type TagListSchema = Tag & {
  menu_counts?: number;
};

export interface TagDetailSchema {
  id?: number;
  name?: string | null;
  bannerUrl?: string | null;
  variant?: string | null;
  metaDescriptionType?: string | null;
  menuCounts?: number;
  fortuneTellersAmount?: number;
}

export interface Fortune {
  id?: number;
  type?: FortuneTypeEnum;
  title?: string;
  imageUrl?: string | null;
  imageProcessedUrl?: string | null;
  price?: number;
  averageScore?: number;
  isFavorited?: boolean;
  isOnlyFirstTime?: boolean;
  isRepeater?: boolean;
}

export interface TelFortune {
  id?: number;
  type?: TelFortuneTypeEnum;
  title?: string;
  imageUrl?: string | null;
  imageProcessedUrl?: string | null;
  costPerMinute?: number;
  averageScore?: number;
  isFavorited?: boolean;
  isOnlyFirstTime?: boolean;
  isRepeater?: boolean;
}

export interface Review {
  id?: number;
  title?: string;
  content?: string;
  score?: number;
  createdAt?: string;
  user?: User;
}

export interface FortuneTellerDailyInsightSummary {
  chatRevenues?: number;
  telRevenues?: number;
  chatCustomers?: number;
  telCustomers?: number;
  chatArppu?: number;
  telArppu?: number;
  totalChatWaitingHours?: number;
  totalTelWaitingHours?: number;
  chatOrdersWhileWaiting?: number;
  telOrdersWhileWaiting?: number;
  totalChatFortuneTellers?: number;
  totalTelFortuneTellers?: number;
  chatOrdersRank?: number | null;
  telOrdersRank?: number | null;
  chatArppuRank?: number | null;
  telArppuRank?: number | null;
  chatCustomersRank?: number | null;
  telCustomersRank?: number | null;
  chatOrdersRankDiff?: number | null;
  telOrdersRankDiff?: number | null;
  chatArppuRankDiff?: number | null;
  telArppuRankDiff?: number | null;
  chatCustomersRankDiff?: number | null;
  telCustomersRankDiff?: number | null;
}

export interface Coupon {
  id?: number;
  couponId?: number;
  name?: string;
  discountAmount?: number;
  discountRate?: number;
  forType?: string;
  isCombined?: boolean;
  planToRedeem?: boolean;
  minimumPrice?: number;
  lastHistory?: string;
  expiredAt?: string;
}

export interface CouponHistory {
  id?: number;
  historyType?: string;
  coupon?: Coupon;
  createdAt?: string;
}

export interface FreeFortuneChatQuestionDetailSchema {
  id?: number;
  freeFortuneThemeId?: number;
  resultType?: string;
  body?: string;
  description?: string;
  imageUrl?: string;
  thumbnailUrl?: string;
}

export interface FreeFortuneThemeDetailSchema {
  id?: number;
  name?: string;
  order?: number;
  freeFortuneChatQuestions?: FreeFortuneChatQuestionDetailSchema[];
}

export interface FreeFortuneBotDetailSchema {
  id?: string;
  name?: string;
  resultPageUrl?: string | null;
  trackingId?: string | null;
  freeFortuneThemes?: FreeFortuneThemeDetailSchema[];
}

export interface CreateOrderPaymentsRequestParams {
  /** chat | tel */
  type: CreateOrderPaymentsRequestParamsTypeEnum;
  /** チャットまたは電話鑑定のメニューID */
  menuId: number;
  /** ニックネーム */
  name: string;
  /** 生年月日 */
  birthday: string;
  /** 性別 */
  gender: string;
  /** 血液型 */
  bloodtype: string;
  /** 相談内容 */
  consultContent: string;
  /** 電話番号 */
  phoneNumber?: string;
  /** 支払い方法 */
  paymentMethod: string;
  /** ポイントを利用するかどうか */
  usePoint: boolean;
  /** クーポンを利用するかどうか（下記のクーポンIDと役割重複していないか？） */
  useCoupon: boolean;
  /** 利用するクーポンのID */
  useUserCouponOwnershipId: string;
  /** 利用するクーポンのID？（use_user_coupon_ownership_idでやりたいこと実現できる？） */
  useCouponId: string;
  /** 支払いを行うコンビニ名 */
  cvsName: string;
  /** 名 */
  firstName: string;
  /** 性 */
  lastName: string;
  /** 性（カナ） */
  lastNameKana: string;
  /** 待機中の申し込みかどうか */
  fromNow: boolean;
}

export interface CreatePaymentWithSignupRequestParams {
  /** 鑑定ID */
  fortuneId?: number;
  /** メールアドレス */
  email?: string;
  /** パスワード */
  password?: string;
  /** パスワード確認 */
  passwordConfirmation?: string;
  /** 支払い方法 */
  paymentMethod?: string;
  /** Payjpトークン */
  payjpToken?: string;
  /** 名前 */
  name?: string;
  /** 生年月日 */
  birthday?: string;
  /** 血液型 */
  bloodtype?: string;
}

export interface CreateCouponRequestParams {
  coupon?: {
    /** クーポン名 */
    name: string;
    /** 割引金額 */
    discountAmount: number;
    /** 最低金額 */
    minimumPrice: number;
    /** クーポンの種類 */
    forType: string;
  };
  couponFortuneTellers?: {
    /** CSVファイル */
    csvFile: string;
  };
}

export interface LegacyPaginationMetadata {
  totalPages?: number;
}

export interface PaginationMetadata {
  limit?: number;
  pages?: number;
  page?: number;
  nextPage?: number | null;
  prevPage?: number | null;
  firstPage?: boolean;
  lastPage?: boolean;
  outOfRange?: boolean;
  total?: number;
}

export interface FortuneTellerListResponse {
  fortuneTellers?: FortuneTeller[];
  meta?: LegacyPaginationMetadata;
}

export interface FortuneTellerResponse {
  fortuneTeller?: {
    id: number;
    selfIntroduction?: string | null;
    averageScore: number;
    reviewCounts: number;
    havePublishedChat: boolean;
    havePublishedTel: boolean;
    chatAcceptFlag: boolean;
    telAcceptFlag: boolean;
    waitingFlag: boolean;
    telWaitingFlag: boolean;
    isBusy: boolean;
    joinedSupportProgram: boolean;
    isOfficial: boolean;
    isDeleted: boolean;
    name: string;
    imageUrl: string;
    thumbnailUrl: string;
    bannerUrl?: string | null;
    processedBannerUrl?: string | null;
    bestPriceTelFortune?: {
      id: number;
      type: FortuneTellerResponseTypeEnum;
      title: string;
      imageUrl: string;
      costPerMinute: number;
      averageScore: number;
      isOnlyFirstTime: boolean;
      isFavorited: boolean;
      isRepeater: boolean;
    } | null;
    featureTags?: {
      id: number;
      name: string;
      bannerUrl: string;
      variant: string;
      metaDescriptionType: string;
      menuCounts: number;
    }[];
    isCalling?: boolean;
    isBlocked?: boolean;
    achievement?: Achievement;
    sampleChatRoom?: SampleChatRoom | null;
    divinationTags?: Tag[];
    fortunes?: Fortune[];
    telFortunes?: TelFortune[];
    reviews?: Review[];
  };
}

export interface FortuneTellersDailyInsightListResponse {
  dailyInsights?: FortuneTellersDeilyInsight[];
  meta?: PaginationMetadata;
}

export interface FortuneTellersDailyInsightSummaryResponse {
  summary?: FortuneTellerDailyInsightSummary;
}

export interface CreatedChatOrderResponse {
  fortuneApplication: {
    id: number;
    name: string;
    gender: string;
    bloodtype: string;
    consultContent: string;
    chatRoomId: number;
    fortune: {
      id: number;
      type: string;
      title: string;
      imageUrl: string | null;
      imageProcessedUrl: string | null;
      price: number;
      averageScore: number;
      isFavorited: boolean;
      isOnlyFirstTime: boolean;
      isRepeater: boolean;
    };
  };
}

export interface CreatedTelOrderResponse {
  telFortuneApplication: {
    id: number;
    name: string;
    gender: string;
    bloodtype: string;
    consultContent: string;
    chatRoomId: number;
  };
}

export interface PaymentWithSignupResponse {
  user?: {
    id: number;
    provider: string;
  };
  order?: {
    id: number;
  };
}

export interface OrderResponse {
  order?: {
    id?: number;
    status?: string | null;
    statusIndex?: number | null;
    statuses?: string[];
    campaignId?: string | null;
    phoneNumber?: string | null;
    isOrderedWhileWaiting?: boolean;
    isSentResult?: boolean;
    canceled?: boolean;
    user?: {
      id?: number;
      uuid?: string;
      topicId?: string | null;
      campaignId?: string | null;
      email?: string | null;
      unconfirmedEmail?: string | null;
      confirmed?: boolean;
      name?: string | null;
      totalPointAmount?: number;
      readTopicAt?: string | null;
      clickedCampaignAt?: string | null;
      confirmedAt?: string | null;
      createdAt?: string;
      updatedAt?: string;
      deletedAt?: string | null;
    };
    menu?: {
      id?: number;
      type?: string;
      title?: string;
      imageUrl?: string | null;
      createdAt?: string;
    };
    payment?: {
      id?: number;
      amount?: number | null;
      fee?: number;
      usedPoint?: number;
      couponDiscountAmount?: number;
      paymentMethod?: string;
      dueDate?: string | null;
      paidAt?: string | null;
      transferred?: boolean | null;
      transferredAt?: string | null;
      unpaidAmount?: number;
    };
    card?: {
      brand?: string;
      expMonth?: number;
      expYear?: number;
      last4?: string;
    } | null;
    createdAt?: string;
    updatedAt?: string;
  };
}

export interface CouponHistoryListResponse {
  couponHistories?: CouponHistory[];
}

export interface UserCouponListResponse {
  coupons?: Coupon[];
}

export interface TagListResponse {
  tags?: TagListSchema[];
}

export interface TagDetailResponse {
  tag?: TagDetailSchema;
}

export interface FreeFortuneBotResponse {
  freeFortuneBot?: FreeFortuneBotDetailSchema;
}

export type FortuneTellerTypeEnum = "chat" | "tel" | "subscription";

export type FortuneTypeEnum = "chat" | "tel" | "subscription";

export type TelFortuneTypeEnum = "chat" | "tel" | "subscription";

/** chat | tel */
export type CreateOrderPaymentsRequestParamsTypeEnum = "chat" | "tel";

export type FortuneTellerResponseTypeEnum = "chat" | "tel" | "subscription";

export interface MeDailyInsightsListParams {
  page?: string;
  dateRange?: string;
}

export interface MeDailyInsightsSummaryListParams {
  dateRange?: string;
}

export interface FortuneTellersListParams {
  /** @default 1 */
  page?: number;
  /** @default 10 */
  limit?: number;
  menuType?: MenuTypeEnum;
  waitingStatus?: WaitingStatusEnum;
  /** @example 1 */
  tagId?: number;
  /** @example "love" */
  categoryAlias?: string;
  orderBy?: OrderByEnum;
  shuffle?: ShuffleEnum;
  /** @example "tarot" */
  fortuneTellerTagNames?: string;
}

export type MenuTypeEnum = "chat" | "tel";

export type WaitingStatusEnum = "waiting";

export type OrderByEnum = "created_at" | "repeat_rate" | "gross_ltv";

export type ShuffleEnum = "true" | "false";

export type FortuneTellersListParams1MenuTypeEnum = "chat" | "tel";

export type FortuneTellersListParams1WaitingStatusEnum = "waiting";

export type FortuneTellersListParams1OrderByEnum = "created_at" | "repeat_rate" | "gross_ltv";

export type FortuneTellersListParams1ShuffleEnum = "true" | "false";

export interface OrdersApplyCouponPartialUpdateParams {
  userCouponId: number;
  id: number;
}

export interface UsersCouponsDetailParams {
  orderId?: number;
  userId: number;
}

export interface TagsListParams {
  metaDescriptionType?: MetaDescriptionTypeEnum;
  variant?: VariantEnum;
  /** @example "1,2,3" */
  ids?: string;
}

export type MetaDescriptionTypeEnum = "scene" | "divination" | "feature" | "sub_feature" | "management";

export type VariantEnum = "scene" | "divination" | "feature" | "sub_feature" | "management";

export type TagsListParams1MetaDescriptionTypeEnum = "scene" | "divination" | "feature" | "sub_feature" | "management";

export type TagsListParams1VariantEnum = "scene" | "divination" | "feature" | "sub_feature" | "management";

export namespace Admin {
  /**
   * No description
   * @tags Admin - Daily Insights
   * @name MeDailyInsightsList
   * @summary List daily insights
   * @request GET:/admin/me/daily_insights
   * @response `200` `FortuneTellersDailyInsightListResponse` daily insights list
   */
  export namespace MeDailyInsightsList {
    export type RequestParams = {};
    export type RequestQuery = {
      page?: string;
      date_range?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FortuneTellersDailyInsightListResponse;
  }
  /**
   * No description
   * @tags Admin - Daily Insights
   * @name MeDailyInsightsSummaryList
   * @summary Summary of daily insights
   * @request GET:/admin/me/daily_insights/summary
   * @response `200` `FortuneTellersDailyInsightSummaryResponse` daily insights summary
   */
  export namespace MeDailyInsightsSummaryList {
    export type RequestParams = {};
    export type RequestQuery = {
      date_range?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FortuneTellersDailyInsightSummaryResponse;
  }
}

export namespace FortuneTellers {
  /**
   * No description
   * @tags FortuneTellers
   * @name FortuneTellersList
   * @summary Get a list of fortune tellers
   * @request GET:/fortune_tellers
   * @response `200` `FortuneTellerListResponse` fortune tellers found
   */
  export namespace FortuneTellersList {
    export type RequestParams = {};
    export type RequestQuery = {
      /** @default 1 */
      page?: number;
      /** @default 10 */
      limit?: number;
      menu_type?: FortuneTellersListParams1MenuTypeEnum;
      waiting_status?: FortuneTellersListParams1WaitingStatusEnum;
      /** @example 1 */
      tag_id?: number;
      /** @example "love" */
      category_alias?: string;
      order_by?: FortuneTellersListParams1OrderByEnum;
      shuffle?: FortuneTellersListParams1ShuffleEnum;
      /** @example "tarot" */
      fortune_teller_tag_names?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FortuneTellerListResponse;
  }
  /**
   * No description
   * @tags FortuneTellers
   * @name FortuneTellersDetail
   * @summary Get a fortune teller
   * @request GET:/fortune_tellers/{id}
   * @response `200` `FortuneTellerResponse` fortune teller found
   */
  export namespace FortuneTellersDetail {
    export type RequestParams = {
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FortuneTellerResponse;
  }
}

export namespace FreeFortuneBots {
  /**
   * No description
   * @tags Free Fortune Bots
   * @name FreeFortuneBotsDetail
   * @summary Retrieves a free fortune bot
   * @request GET:/free_fortune_bots/{id}
   * @response `200` `FreeFortuneBotResponse` free fortune bot found
   * @response `404` `void` free fortune bot not found
   */
  export namespace FreeFortuneBotsDetail {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FreeFortuneBotResponse;
  }
}

export namespace Orders {
  /**
   * No description
   * @tags Orders
   * @name PaymentsCreate
   * @summary Create a order
   * @request POST:/orders/payments
   * @response `201` `(CreatedChatOrderResponse | CreatedTelOrderResponse)` order created
   */
  export namespace PaymentsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateOrderPaymentsRequestParams;
    export type RequestHeaders = {};
    export type ResponseBody = CreatedChatOrderResponse | CreatedTelOrderResponse;
  }
  /**
   * No description
   * @tags Orders
   * @name PaymentWithSignupCreate
   * @summary Create user and order
   * @request POST:/orders/payment_with_signup
   * @response `201` `PaymentWithSignupResponse` user and order created
   */
  export namespace PaymentWithSignupCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreatePaymentWithSignupRequestParams;
    export type RequestHeaders = {};
    export type ResponseBody = PaymentWithSignupResponse;
  }
}

export namespace SuperAdmin {
  /**
   * No description
   * @tags SuperAdminCouponHistories
   * @name OrdersCouponHistoriesDetail
   * @summary Get a order coupon histories
   * @request GET:/super_admin/orders/{order_id}/coupon_histories
   * @secure
   * @response `200` `CouponHistoryListResponse` order coupon histories found
   */
  export namespace OrdersCouponHistoriesDetail {
    export type RequestParams = {
      orderId: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = CouponHistoryListResponse;
  }
  /**
   * No description
   * @tags SuperAdmin#Coupons
   * @name CouponsCreate
   * @summary Create a coupon
   * @request POST:/super_admin/coupons
   * @secure
   * @response `201` `void` coupon created
   */
  export namespace CouponsCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = CreateCouponRequestParams;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags SuperAdminMessages
   * @name MessagesDelete
   * @summary Delete a message
   * @request DELETE:/super_admin/messages/{id}
   * @secure
   * @response `204` `void` message deleted
   */
  export namespace MessagesDelete {
    export type RequestParams = {
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags SuperAdminOrders
   * @name OrdersDetail
   * @summary Get a order
   * @request GET:/super_admin/orders/{id}
   * @secure
   * @response `200` `OrderResponse` order found
   */
  export namespace OrdersDetail {
    export type RequestParams = {
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderResponse;
  }
  /**
   * No description
   * @tags SuperAdminOrders
   * @name OrdersCancelPartialUpdate
   * @summary Cancel a order
   * @request PATCH:/super_admin/orders/{id}/cancel
   * @secure
   * @response `200` `OrderResponse` order canceled
   */
  export namespace OrdersCancelPartialUpdate {
    export type RequestParams = {
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderResponse;
  }
  /**
   * No description
   * @tags SuperAdminOrders
   * @name OrdersExceptionalCancelPartialUpdate
   * @summary Exceptional cancel a order
   * @request PATCH:/super_admin/orders/{id}/exceptional_cancel
   * @secure
   * @response `200` `OrderResponse` order exceptional canceled
   */
  export namespace OrdersExceptionalCancelPartialUpdate {
    export type RequestParams = {
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderResponse;
  }
  /**
   * No description
   * @tags SuperAdminOrders
   * @name OrdersApplyCouponPartialUpdate
   * @summary Apply coupon to a order
   * @request PATCH:/super_admin/orders/{id}/apply_coupon
   * @secure
   * @response `200` `OrderResponse` coupon applied
   */
  export namespace OrdersApplyCouponPartialUpdate {
    export type RequestParams = {
      id: number;
    };
    export type RequestQuery = {
      user_coupon_id: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = OrderResponse;
  }
  /**
   * No description
   * @tags SuperAdminSubscriptionChatMessages
   * @name SubscriptionChatMessagesDelete
   * @summary Delete a message
   * @request DELETE:/super_admin/subscription_chat_messages/{id}
   * @secure
   * @response `204` `void` message deleted
   */
  export namespace SubscriptionChatMessagesDelete {
    export type RequestParams = {
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags SuperAdminTelFortuneMessages
   * @name TelFortuneMessagesDelete
   * @summary Delete a message
   * @request DELETE:/super_admin/tel_fortune_messages/{id}
   * @secure
   * @response `204` `void` message deleted
   */
  export namespace TelFortuneMessagesDelete {
    export type RequestParams = {
      id: number;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags SuperAdminUserCoupons
   * @name UsersCouponsDetail
   * @summary Get a user coupons
   * @request GET:/super_admin/users/{user_id}/coupons
   * @secure
   * @response `200` `UserCouponListResponse` user coupons found
   */
  export namespace UsersCouponsDetail {
    export type RequestParams = {
      userId: number;
    };
    export type RequestQuery = {
      order_id?: number;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = UserCouponListResponse;
  }
}

export namespace Tags {
  /**
   * No description
   * @tags Tags
   * @name TagsList
   * @summary List tags
   * @request GET:/tags
   * @response `200` `TagListResponse` tags found
   */
  export namespace TagsList {
    export type RequestParams = {};
    export type RequestQuery = {
      meta_description_type?: TagsListParams1MetaDescriptionTypeEnum;
      variant?: TagsListParams1VariantEnum;
      /** @example "1,2,3" */
      ids?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TagListResponse;
  }
  /**
   * No description
   * @tags Tags
   * @name TagsDetail
   * @summary Show tag
   * @request GET:/tags/{id}
   * @response `200` `TagDetailResponse` tag found
   */
  export namespace TagsDetail {
    export type RequestParams = {
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = TagDetailResponse;
  }
}

export namespace TmpOrders {
  /**
   * No description
   * @tags TmpOrders
   * @name TmpOrdersCreate
   * @summary Send notification for completed tmp order
   * @request POST:/tmp_orders
   * @response `200` `void` notification sent
   */
  export namespace TmpOrdersCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * No description
   * @tags TmpOrders
   * @name RequestedDatetimeCreate
   * @summary Send notification for completed tmp order
   * @request POST:/tmp_orders/requested_datetime
   * @response `200` `void` notification sent
   */
  export namespace RequestedDatetimeCreate {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "https://{defaultHost}/v1" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title API V1
 * @version v1
 * @baseUrl https://{defaultHost}/v1
 */
export class Api<SecurityDataType extends unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  admin = {
    /**
     * No description
     *
     * @tags Admin - Daily Insights
     * @name MeDailyInsightsList
     * @summary List daily insights
     * @request GET:/admin/me/daily_insights
     * @response `200` `FortuneTellersDailyInsightListResponse` daily insights list
     */
    meDailyInsightsList: (query: MeDailyInsightsListParams, params: RequestParams = {}) =>
      this.http.request<FortuneTellersDailyInsightListResponse, any>({
        path: `/admin/me/daily_insights`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Admin - Daily Insights
     * @name MeDailyInsightsSummaryList
     * @summary Summary of daily insights
     * @request GET:/admin/me/daily_insights/summary
     * @response `200` `FortuneTellersDailyInsightSummaryResponse` daily insights summary
     */
    meDailyInsightsSummaryList: (query: MeDailyInsightsSummaryListParams, params: RequestParams = {}) =>
      this.http.request<FortuneTellersDailyInsightSummaryResponse, any>({
        path: `/admin/me/daily_insights/summary`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  fortuneTellers = {
    /**
     * No description
     *
     * @tags FortuneTellers
     * @name FortuneTellersList
     * @summary Get a list of fortune tellers
     * @request GET:/fortune_tellers
     * @response `200` `FortuneTellerListResponse` fortune tellers found
     */
    fortuneTellersList: (query: FortuneTellersListParams, params: RequestParams = {}) =>
      this.http.request<FortuneTellerListResponse, any>({
        path: `/fortune_tellers`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags FortuneTellers
     * @name FortuneTellersDetail
     * @summary Get a fortune teller
     * @request GET:/fortune_tellers/{id}
     * @response `200` `FortuneTellerResponse` fortune teller found
     */
    fortuneTellersDetail: (id: number, params: RequestParams = {}) =>
      this.http.request<FortuneTellerResponse, any>({
        path: `/fortune_tellers/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  freeFortuneBots = {
    /**
     * No description
     *
     * @tags Free Fortune Bots
     * @name FreeFortuneBotsDetail
     * @summary Retrieves a free fortune bot
     * @request GET:/free_fortune_bots/{id}
     * @response `200` `FreeFortuneBotResponse` free fortune bot found
     * @response `404` `void` free fortune bot not found
     */
    freeFortuneBotsDetail: (id: string, params: RequestParams = {}) =>
      this.http.request<FreeFortuneBotResponse, void>({
        path: `/free_fortune_bots/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  orders = {
    /**
     * No description
     *
     * @tags Orders
     * @name PaymentsCreate
     * @summary Create a order
     * @request POST:/orders/payments
     * @response `201` `(CreatedChatOrderResponse | CreatedTelOrderResponse)` order created
     */
    paymentsCreate: (data: CreateOrderPaymentsRequestParams, params: RequestParams = {}) =>
      this.http.request<CreatedChatOrderResponse | CreatedTelOrderResponse, any>({
        path: `/orders/payments`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Orders
     * @name PaymentWithSignupCreate
     * @summary Create user and order
     * @request POST:/orders/payment_with_signup
     * @response `201` `PaymentWithSignupResponse` user and order created
     */
    paymentWithSignupCreate: (data: CreatePaymentWithSignupRequestParams, params: RequestParams = {}) =>
      this.http.request<PaymentWithSignupResponse, any>({
        path: `/orders/payment_with_signup`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  superAdmin = {
    /**
     * No description
     *
     * @tags SuperAdminCouponHistories
     * @name OrdersCouponHistoriesDetail
     * @summary Get a order coupon histories
     * @request GET:/super_admin/orders/{order_id}/coupon_histories
     * @secure
     * @response `200` `CouponHistoryListResponse` order coupon histories found
     */
    ordersCouponHistoriesDetail: (orderId: number, params: RequestParams = {}) =>
      this.http.request<CouponHistoryListResponse, any>({
        path: `/super_admin/orders/${orderId}/coupon_histories`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SuperAdmin#Coupons
     * @name CouponsCreate
     * @summary Create a coupon
     * @request POST:/super_admin/coupons
     * @secure
     * @response `201` `void` coupon created
     */
    couponsCreate: (data: CreateCouponRequestParams, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/super_admin/coupons`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SuperAdminMessages
     * @name MessagesDelete
     * @summary Delete a message
     * @request DELETE:/super_admin/messages/{id}
     * @secure
     * @response `204` `void` message deleted
     */
    messagesDelete: (id: number, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/super_admin/messages/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SuperAdminOrders
     * @name OrdersDetail
     * @summary Get a order
     * @request GET:/super_admin/orders/{id}
     * @secure
     * @response `200` `OrderResponse` order found
     */
    ordersDetail: (id: number, params: RequestParams = {}) =>
      this.http.request<OrderResponse, any>({
        path: `/super_admin/orders/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SuperAdminOrders
     * @name OrdersCancelPartialUpdate
     * @summary Cancel a order
     * @request PATCH:/super_admin/orders/{id}/cancel
     * @secure
     * @response `200` `OrderResponse` order canceled
     */
    ordersCancelPartialUpdate: (id: number, params: RequestParams = {}) =>
      this.http.request<OrderResponse, any>({
        path: `/super_admin/orders/${id}/cancel`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SuperAdminOrders
     * @name OrdersExceptionalCancelPartialUpdate
     * @summary Exceptional cancel a order
     * @request PATCH:/super_admin/orders/{id}/exceptional_cancel
     * @secure
     * @response `200` `OrderResponse` order exceptional canceled
     */
    ordersExceptionalCancelPartialUpdate: (id: number, params: RequestParams = {}) =>
      this.http.request<OrderResponse, any>({
        path: `/super_admin/orders/${id}/exceptional_cancel`,
        method: "PATCH",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SuperAdminOrders
     * @name OrdersApplyCouponPartialUpdate
     * @summary Apply coupon to a order
     * @request PATCH:/super_admin/orders/{id}/apply_coupon
     * @secure
     * @response `200` `OrderResponse` coupon applied
     */
    ordersApplyCouponPartialUpdate: (
      { id, ...query }: OrdersApplyCouponPartialUpdateParams,
      params: RequestParams = {},
    ) =>
      this.http.request<OrderResponse, any>({
        path: `/super_admin/orders/${id}/apply_coupon`,
        method: "PATCH",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags SuperAdminSubscriptionChatMessages
     * @name SubscriptionChatMessagesDelete
     * @summary Delete a message
     * @request DELETE:/super_admin/subscription_chat_messages/{id}
     * @secure
     * @response `204` `void` message deleted
     */
    subscriptionChatMessagesDelete: (id: number, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/super_admin/subscription_chat_messages/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SuperAdminTelFortuneMessages
     * @name TelFortuneMessagesDelete
     * @summary Delete a message
     * @request DELETE:/super_admin/tel_fortune_messages/{id}
     * @secure
     * @response `204` `void` message deleted
     */
    telFortuneMessagesDelete: (id: number, params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/super_admin/tel_fortune_messages/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SuperAdminUserCoupons
     * @name UsersCouponsDetail
     * @summary Get a user coupons
     * @request GET:/super_admin/users/{user_id}/coupons
     * @secure
     * @response `200` `UserCouponListResponse` user coupons found
     */
    usersCouponsDetail: ({ userId, ...query }: UsersCouponsDetailParams, params: RequestParams = {}) =>
      this.http.request<UserCouponListResponse, any>({
        path: `/super_admin/users/${userId}/coupons`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  tags = {
    /**
     * No description
     *
     * @tags Tags
     * @name TagsList
     * @summary List tags
     * @request GET:/tags
     * @response `200` `TagListResponse` tags found
     */
    tagsList: (query: TagsListParams, params: RequestParams = {}) =>
      this.http.request<TagListResponse, any>({
        path: `/tags`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Tags
     * @name TagsDetail
     * @summary Show tag
     * @request GET:/tags/{id}
     * @response `200` `TagDetailResponse` tag found
     */
    tagsDetail: (id: string, params: RequestParams = {}) =>
      this.http.request<TagDetailResponse, any>({
        path: `/tags/${id}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  tmpOrders = {
    /**
     * No description
     *
     * @tags TmpOrders
     * @name TmpOrdersCreate
     * @summary Send notification for completed tmp order
     * @request POST:/tmp_orders
     * @response `200` `void` notification sent
     */
    tmpOrdersCreate: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/tmp_orders`,
        method: "POST",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TmpOrders
     * @name RequestedDatetimeCreate
     * @summary Send notification for completed tmp order
     * @request POST:/tmp_orders/requested_datetime
     * @response `200` `void` notification sent
     */
    requestedDatetimeCreate: (params: RequestParams = {}) =>
      this.http.request<void, any>({
        path: `/tmp_orders/requested_datetime`,
        method: "POST",
        ...params,
      }),
  };
}
