import { HttpClient } from "@miror/types";
import qs from "qs";
import { mapKeysSnakeCase, mapKeysCamelCase } from "shared/utils";

export const hosts = {
  miror: `${process.env.NEXT_PUBLIC_API_ENDPOINT}/v1/`,
  media: `https://media-api.miror.jp/v1/`,
  works: `https://works.dears-fortune.jp/api/v1/`,
};

export const headers = {
  json: {
    headers: {
      "Content-Type": "application/json",
    },
  },
  formData: {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  },
};

const baseConfig = {
  ...headers.json,
  paramsSerializer: (params: any) =>
    qs.stringify(params, { arrayFormat: "brackets" }),
  timeout: 20000,
};

export const axiosConfigs = {
  miror: {
    ...baseConfig,
    baseURL: hosts.miror,
    withCredentials: true,
  },
};

const mirorHttpClient = new HttpClient(axiosConfigs.miror);

mirorHttpClient.instance.interceptors.request.use(
  (config) => {
    config.data = mapKeysSnakeCase(config.data);
    config.params = mapKeysSnakeCase(config.params);

    return config;
  },
  (error) => Promise.reject(error)
);

mirorHttpClient.instance.interceptors.response.use(
  (response) => {
    response.data = mapKeysCamelCase(response.data);
    return response;
  },
  (error) => Promise.reject(error)
);

export const httpClients = {
  miror: mirorHttpClient,
};
